.quill {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.ql-container.ql-snow {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 1rem;
  border: 0;
}

.ql-toolbar.ql-snow {
  border-top: 0;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-right: 0;
}

// Quill Snow will set background on syntax blocks. This overwrites it
.ql-snow .ql-editor pre.ql-syntax {
  @extend .hljs;
}

#code-container .ql-syntax {
  @extend .hljs;
  margin-top: 35px;
  border-radius: 0.25rem;
  font-size: 0.7em;
  display: block;
  text-align: left !important;
  overflow: hidden;
}

@import "~highlight.js/styles/foundation";
